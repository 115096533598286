.sidebar__link{
    .orange-icon{
        background: transparent;
    }
}

.sidebar__link-active{
    .orange-icon:not(.muted){
        background: $color-orange;
    }

    .sidebar__link::before{
        opacity: 1;
    }
}

.icon__size_default{
    height: 40px;
    width: 40px;
}

.icon__size_small{
    height: 30px;
    width: 30px;


    svg{
        width: 30px;
        height:30px;
    }
}

.icon__size_24{
    height: 24px;
    width: 24px;


    svg{
        width: 24px;
        height:24px;
    }
}

.icon__size_extrasmall{
    height: 20px;
    width: 20px;


    svg{
        width: 20px;
        height:20px;
    }
}

.icon__size_medium{
    height: 50px;
    width: 50px;

    svg{
        width:50px;
        height:50px;
    }
}


.icon__padding_medium{
    padding: 9px;

    svg{
        width: auto;
        height: auto;
    }
}

.icon__size_large{
    height: 100px;
    width: 100px;


    svg{
        width:100px;
        height:100px;
    }
}

.icon__color_light_gray{
    & +p{
        color: $color-light-gray;
    }

    &.muted, &.muted + p{
        color:$color-gray
    }
}


.shared__icon{

    display: flex;
    left: 0;
    font-size: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

    &.orange-icon{
        color: $color-light-gray;

        &.active{
            background: $color-orange;
        }
    }

    &.gray-icon{
        color: $color-gray;
        & path{
            fill:$color-gray;
        }
        &.active{
            @include themify($themes){
                background:themed("activeIconColor")
            }
        }
    }

    &.solid-orange-icon{
        color: $color-accent;

        & path{
            fill:$color-accent;
        }
    }

    &.dark-gray-icon{
        color: $color-dark-gray;
        
        & path{
            fill:$color-dark-gray;
        }

        &.active{
            background: $color-gray;
        }
    }

    &.black{
        @include themify($themes){
            color: themed("colorIcon") !important;
        }
    }

    &.black-icon{
        color: black;
        &.active{
            background: $color-light-gray;
        }
    }

    &.orange-icon__light{
        color: white;

        &.active{
            background: $color-orange;
        }
    }

    &.orange-icon__filled{
        color: $color-orange;
    }

    .shared-icon_progress{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        

        &.shared-icon_progress--back{
            color: $color-gray !important;
        }

        &.shared-icon_progress--indicator{
            color: #ff6600 !important;
            z-index:1;
            transform: rotate(270deg) !important;
        }

        

        svg{
            height: 200px !important;
            width: 200px !important;

            circle{
                stroke-width: 2.7;
            }
        }
     
    }

}




