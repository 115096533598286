.cursor-pointer {
    cursor: pointer;
}

.children-cursor-pointer *{
    cursor: pointer !important;
}

.hover-scale {
    transition: 0.3s all ease-in-out;
    transform: scale(1);

    &:hover {
        transition: 0.3s all ease-in-out;
        transform: scale(1.1);
    }
}

.no-outline{
    outline: none;
    box-shadow: none;
    &:focus{
        outline: none;
        box-shadow: none;
    }
}

.hover-clickable{

    background-color: none;
    transition: background-color 0.5s;
  
    &:hover{
      background-color: rgba(0, 0, 0, 0.034);  
      border-radius: 10px;

    }
}

.text-normal{
    text-transform: none;
}

.cursor-grab{
    cursor: grab;
}
.col-p-6{
    flex: 0 0 48%;
}

.col-p-3{
    flex: 0 0 23%;
}

.col-p-4{
    flex: 0 0 32%
}

.checkbox-container {
  width: 25px;
  min-width: 25px;

    height: 25px;
    border-radius: 25px;

    align-items: center;
    justify-content: center;

    .material-checkbox {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 25px;
    }

    & .material-checkbox__image {
      width: 25px;
      height: 25px;
      border-radius: 25px;
      justify-content: center;
      align-items: center;
      transition: all 0.3s ease-in-out;

      &::after {
        border-width: 3px !important;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
      }

      &::after,
      &::before {
        transition: all 0.3s ease-in;
      }
    }

    & input[type="checkbox"] {
      & + span {
        background-image: $orange-gradient;
        border: none;
      }

      &:not(:checked) {
        & + span {
          width: 18px;
          height: 18px;
          border-radius: 18px;
          border: 2px solid white;
          background: transparent;
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
        }
      }
    }
  }

  .overflow-x-auto{
    overflow-x: auto;
  }