.theme-light .activity-indicator .dot {
  width: 5px;
  height: 5px;
  border-radius: 5px;
}

.theme-dark .activity-indicator .dot {
  width: 7px;
  height: 7px;
  border-radius: 7px;
}

.activity-indicator {
  width: 19px;
  height: 19px;
  border-radius: 19px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 0.5px solid rgba($color: #000000, $alpha: 0.07);

  @include themify($themes){

  &.green {
    & .dot {
      box-shadow: 0px 0px 2px 1px themed("greenIndicator");

      background-color: themed("greenIndicator");
    }
  }

  &.red {
    & .dot {
      box-shadow: 0px 0px 2px 1px themed("redIndicator");
      background-color: themed("redIndicator");
    }
  }

  &.gray {
    & .dot {
      box-shadow: 0px 0px 2px 1px themed("grayIndicator");

      background-color: themed("grayIndicator");
    }
  }
}
}
