.card {
  width: 100%;

  height: 100%;

  @include themify($themes) {
    background-color: themed('colorBackgroundBodyLight');
    border-color:  themed('colorBackgroundBodyLight');
  }

  &.card--not-full-height {
    height: auto;
  }
}


  .card__gray{
    @include themify($themes) {
      background-color: themed('colorBackgroundBodyLight');
    }

    .card-body{
      @include themify($themes) {
        background-color: themed('colorBackgroundBodyLight');
      }
    }

    .card-header{
      @include themify($themes) {
        background-color: themed('colorBackgroundBodyLight');
      }

      &.card-title__rounded--bottom{
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
      }
    }

    & .preview-container{
      & #hx-editor_ROOT{
        height: 100%;
        
        @include themify($themes){
          background-color: themed("editorBackground");

        }
        box-shadow: none;

        & .hx-editor-plugin_plugin{
          background: transparent;
        }
      }
    }

  }


.card-body {
  *.hr_sub{
    position:relative;
    left:5px;
  }

}


.card__title {
  margin-bottom: 30px;
  text-transform: uppercase;
  position: relative;

  &:not(:first-child) {
    margin-top: 40px;
  }

  .subhead {
    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.7;
    margin-top: 3px;
  }

  * {
    margin-bottom: 0;
  }

  h5 {
    font-size: 13px;
  }
}

.squared-corner-theme {

  .card-body {
    border-radius: 0;
  }
}

.blocks-with-shadow-theme {

  .card-body {
    box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
  }
}

.card {
  width: 100%;

  height: 100%;


  &.card--not-full-height {
    height: auto;
  }
}

.card-body {
  height: 100%;
  border-radius: 5px;

  @include themify($themes) {
    background-color: themed('colorBackgroundBodyLight');
  }

  & label{
    @include themify($themes) {
      color: themed('colorText');
    } 
  }
}

.card__title {
  @include directify($directions) {
    text-align: directed('left');
  }
  h5.card__title-center {
    text-align: center;
  }
  margin-bottom: 30px;
  text-transform: uppercase;
  position: relative;

  &:not(:first-child) {
    margin-top: 40px;
  }

  .subhead {
    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.7;
    margin-top: 3px;
  }

  * {
    margin-bottom: 0;
  }

  h5 {
    font-size: 13px;
  }
}

.squared-corner-theme {

  .card-body {
    border-radius: 0;
  }
}

.blocks-with-shadow-theme {

  .card-body {
    box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
  }
}


