.topbar {
  width: 100%;
  position: fixed;
  top: 0;
  height: 80px;
  z-index: 99;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.08);

  @include themify($themes) {
    background: themed("colorTopbar");
  }
}

.topbar__wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 80px;
}

.topbar__button {
  width: 80px;
  height: 80px;
  display: flex;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: 0.3s;

  &:focus {
    outline: none;
  }

  &:hover {
    @include themify($themes) {
      background: themed("colorLightHover");
    }
  }

  &.topbar__button--desktop {
    display: none;
  }
}

.topbar__button-icon {
  margin: auto;
  transition: all 0.3s;
  width: 16px;
}

.topbar__avatar {
  height: 100%;
  width: 100%;
  display: flex;
  cursor: pointer;
  position: relative;
  border-radius: 0;
  border: none;
  transition: all 0.3s;
  box-shadow: none;
  padding: 0 15px;
  background-color: transparent;
  justify-content: space-between;
  align-items: center;
  &:hover,
  &:focus,
  &:active,
  &:focus:active {
    @include themify($themes) {
      background-color: themed("colorLightHover");
    }
  }

  &:focus {
    outline: none;
  }

  &:before {
    display: none;
  }
}

.topbar__avatar-img,
.topbar__avatar-name,
.topbar__icon {
  margin: auto 0;
}

.topbar__avatar-img {
  border-radius: 50%;
  height: 36px;
  width: 36px;
}

.topbar__avatar-name {
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  margin-left: 10px;

  @include themify($themes) {
    color: themed("colorDarkText");
  }
}

.topbar__icon {
  margin-left: 8px;
  height: 18px;
  margin-top: auto;
  fill: #b1c3c8;
}

.topbar__menu {
  width: 200px;
  border-radius: 0;
  border: none;
  padding: 15px 0;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  margin-top: 0;

  @include themify($themes) {
    background: themed("colorWhiteBody");
  }

  button {
    padding: 0;

    &:hover {
      @include themify($themes) {
        background-color: themed("colorLightHover");
      }
    }
  }

  *:focus {
    outline: none;
  }

  &.show{
    box-shadow : -5px 6px 10px 0 rgba(0, 0, 0, 0.1)
  }
}

.topbar__menu-wrap {
  z-index: 111;
  position: absolute;
  width: 100%;
  padding: 0;
  right: 0;
}

.topbar__link {
  display: flex;
  padding: 9px 20px;
  transition: all 0.3s;
  height: 32px;
  width: 100%;
  position: relative;
  cursor: pointer;

  @include themify($themes) {
    color: themed("colorDarkText");
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 2px;
    opacity: 0;
    transition: all 0.3s;
    @include colorify($colors) {
      background: colored("backgroundColor");
    }
  }

  &:hover {
    @include themify($themes) {
      background-color: themed("colorLightHover");
    }

    &:before {
      opacity: 1;
    }
  }
}

.topbar__link-title {
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  @include themify($themes) {
    color: themed("colorDarkText");
  }
}

.topbar__link-icon {
  margin-right: 10px;
  font-size: 13px;
  line-height: 13px;

  @include themify($themes) {
    color: themed("colorIcon");
  }
}


.topbar__profile {
  max-width: 250px;
  min-width: 180px;
  height: 100%;
  margin-bottom: 0;
  position: relative;
}

.topbar__collapse {
  position: relative;
  display: none;
  height: 100%;
  &.topbar__collapse--language {
    min-width: 70px;
    display: block;

    & > button {
      padding: 0 4px;
      width: 100%;
    }
  }

  @media screen and (min-width: 568px) {
    display: block;
  }
}

.topbar__collapse-content {
  width: 270px;
  position: absolute;
  right: 0;
  bottom: 20px;
  transform: translateY(100%);
  box-shadow: 0 10px 25px 0 rgba(33, 36, 50, 0.13);
  z-index: 110;

  @include themify($themes) {
    background: themed("colorWhiteBody");
  }

  &.topbar__collapse-content--language {
    max-width: 75px;
    padding: 10px 0;
    bottom: 0;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    left: 50%;
    transform: translate(-50%, 100%);
  }

  @media screen and (min-width: 520px) {
    width: 330px;
  }
}

.topbar__language-btn {
  padding: 4px 15px;
  width: 100%;
  border: none;
  background: transparent;
  cursor: pointer;
  text-align: left;
  font-size: 13px;
  line-height: 16px;
  transition: 0.3s;

  &:hover {
    color: $color-accent;
  }
}

.topbar__language-btn-title {
  display: flex;
  font-size: 11px;
  align-items: center;
  margin: auto 0;

  @include themify($themes) {
    color: themed("colorDarkText");
  }

  &:not(:last-child) {
    margin-right: 5px;
  }

  img {
    height: 11px;
    width: 16px;
    margin-right: 4px;
  }
}

.topbar__back {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 110;
  background: transparent;
  border: none;
  padding: 0;
  &:focus, &:active{
    outline: none;
  }
}



.topbar__btn {
  font-size: 18px;
  height: 100%;
  padding: 0 10px;
  cursor: pointer;
  position: relative;
  display: flex;
  border: none;
  background: transparent;
  transition: all 0.3s;

  @include themify($themes) {
    color: themed("colorDarkText");
  }

  &:hover {
    @include themify($themes) {
      background-color: themed("colorLightHover");
    }
  }

  &:focus{
    outline: none;
  }

  svg {
    margin: auto;
    height: 18px;
    width: 18px;
    fill: #b1c3c8;
  }

  &.topbar__btn--new {
    .topbar__btn-new-label {
      position: absolute;
      right: 9px;
      top: 20px;

      & > div {
        position: relative;

        &:before {
          background-color: rgba(224, 83, 111, 0.2);
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          animation: beforePulse 1.5s infinite;
        }

        &:after {
          height: 7px;
          width: 7px;
          background-color: #e0536f;
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          border-radius: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  @keyframes beforePulse {
    from {
      width: 7px;
      height: 7px;
    }
    25% {
      width: 13px;
      height: 13px;
    }
    to {
      width: 7px;
      height: 7px;
    }
  }
}


.topbar__search {
  display: none;
  margin: auto 0;
  padding: 0;
  position: relative;
  background: #fafafa;
  border-radius: 5px;
  align-items: center;
  right: 50px;
}

.topbar__search-field {
  background-color: transparent;
  width: 0;
  transition: all 0.3s;
  opacity: 0;
  margin: auto auto auto 0;
  border: none;
  border-radius: 13px;
  height: 40px;
  
  padding-left: 10px;
  padding-right: 46px;

  &.topbar__search-field--open {
    width: 300px;
    opacity: 1;
    margin-left: 10px;

    & + button {
      right: 10px;
    }
  }

  &:focus {
    outline: none;
  }
}

.topbar__search-btn {
  height: 26px;
  width: 26px;
  border-radius: 13px;
  border: none;
  padding: 0;
  background: transparent;
  position: absolute;
  right: 0;

  &:hover {
    @include themify($themes) {
      background-color: themed("colorWhiteBody");
    }
  }
}

@media screen and (min-width: 480px) {
  .topbar__avatar-name {
    display: block;
  }

  .topbar__menu {
    width: 100%;
    left: 0 !important;
  }
}

@media screen and (min-width: 576px) {
  .topbar__button {
    &.topbar__button--desktop {
      display: block;
    }

    &.topbar__button--mobile {
      display: none;
    }
  }

  .topbar.topbar--navigation {
    .topbar__button.topbar__button--mobile {
      display: block;
    }
  }
}

@media screen and (min-width: 768px) {
  .topbar__search {
    display: flex;
  }
}


.layout:not(.layout--without-topbar){
  & .topbar__login {
    position: fixed;
    top: 0;
    display: none;
    height: 125px;
    background-color: black;
    width: 100vw;
    z-index: 99;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    .logo {
      height: 80%;
      padding-right: 35px;
    }
  
    .logotype {
      height:  50%;
    }
  
    img{
      width: initial;
    }
  
    @media screen and (max-width: 825px) {
      display: flex;
    }
  
    @media screen and (max-width: 520px) {
      .logo {
        height: 60%;
        padding-right: 35px;
      }
    
      .logotype {
        height:  30%;
      }
    }
  }
  
}

.topbar__notifications{
  width: 120px;
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin-right: 30px;
  position: relative;

  & .icon__notification{
    @include themify($themes){
      color: themed("notification")
    }
    cursor: pointer;
    position: relative;
    margin-left: 30px;
  }

}

.notification--active{
  position: absolute;
  right: -3px;
  top:-3px;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: $color-orange
}