.colored-radio-icon{
    
    div{
        position: absolute;
    }

    .colored-radio-icon--primary{
 
            svg{
                width:18px;
                height:18px;
                position: relative;
                left:3px;
                top:1px;
               
                padding: 1px;
                margin-bottom: 1px;
            }
    }
}