.widget-chart {
  @include themify($themes) {
    & path:nth-child(1) {
      stroke: themed("greenIndicator");
    }
    & text:nth-child(4) {
      fill: themed("greenIndicator");
    }

    & path:nth-child(2) {
      stroke: themed("grayIndicator");
    }
    & text:nth-child(5) {
      fill: themed("grayIndicator");
    }

    & path:nth-child(3) {
      stroke: themed("redIndicator");
    }
    & text:nth-child(6) {
      fill: themed("redIndicator");
    }
  }
}
