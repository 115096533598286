.user--avatar{
    margin: 15px 0;
    border-radius: 10px;

    &.lg{
        width: 40%;
    }

    &.md{
        width: 30%;
    }
}

.account-tab{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    & >p{
        font-size: 20px;
    }

    .outline{
        border: 0.5px solid $color-accent;
        padding: 3px 7px;
        border-radius: 5px;
        color: $color-accent;
        margin-top: 10px;
        margin-bottom: 2.5rem;
    }

}

.account--content_wrapper{
    width: calc(100% + 2.5rem);
    margin-top: auto;
    margin-bottom: 15px;
}


.account--footer{
    color : $color-dark-gray;
    text-align: left;
    & h4{
        padding: 10px 0;
    }
    & span{
        color:$color-gray
    }
}

.account--block{
    border-top: 0.5px solid $color-light-gray;
    padding: 1rem 1.25rem;
}

.account--row{
    display: flex;
    flex-direction: row;
    align-items: center;

    div{
        margin-right: 20px;
    }

    span{
        font-size: 1rem;
        font-weight: bold;
    }
}