.btn-gradient {
  align-self: center;
  padding: 10px 25px;
  width: max-content;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  text-transform: uppercase;
  color: white;
  font-size: 25px;
  border: none;
  border-radius: 10px;
  background-image: $orange-gradient;

  & span {
    font-size: 15px !important;
    line-height: 15px;
  }

  * {
    margin: 0 3px;
  }

  &.red {
    background-image: none;
    background-color: #ff3a31;
  }

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    &.btn-shadow {
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
    }
  }

  &.btn-gradient-outline {
    background-image: none;
    background-color: transparent;
    border: 1px solid black;
    color: black;

    &:not(.black):not(.red) {
      @include themify($themes) {
        border: 1px solid themed(colorButtonOutline);
        color: themed(colorButtonOutline);
      }
    }
    &.red{
      border: 1px solid #ff3a31;
      color: #ff3a31;
    }  
    
  }
  &.text-normal {
    text-transform: none;
  }
  &.small-padding {
    padding: 5px;
  }
  &.medium-padding {
    padding: 10px 15px;
  }
  &.btn-shadow {
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    cursor: not-allowed;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
  }
}
