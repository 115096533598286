.status__bar{

    font-size: 0.8rem;
    padding:10px 2rem;
    border-radius: 5px;
    border-bottom: none;
    text-align: center;
    &.success{
        background: rgb(40,227,68);
        background: linear-gradient(0deg, rgba(40,227,68,1) 0%, rgb(134, 233, 149) 100%); 
        color:white;
    }


}


