.form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  input,
  textarea, .select-input {
    width: 100%;
    padding: 5px 10px;
    font-size: 14px;
    min-height: 70px;
    font-weight: bold;
    transition: border 0.3s;
    background: transparent;

    &::-webkit-input-placeholder {
      color: $color-gray-account;
      
    }
    &::-moz-placeholder {
      color: $color-gray-account;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: $color-gray-account;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: $color-gray-account;
    }

    @include themify($themes) {
      color: themed("colorDarkText");
    }

    &[disabled] {
      @include themify($themes) {
        background: themed("colorBackgroundBodyLight");
      }

    }

  }

  textarea {
    min-height: 85px;
  }

}

.form__form-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  position: relative;
  padding-left: 10px;
  border-radius: 5px;
  overflow: hidden;
  @include themify($themes){
    box-shadow: 0 1px 10px 3px themed("inputShadow");
  }

  @include themify($themes){
    background: themed("inputBackground");
  }

  &::before{
    content: "";
    width: 3px;
    height: 100%;
    background: $color-orange;
    position: absolute;
    left: 0;
    display: block;
  }
}

.form__form-group-field {
  display: flex;
  flex:8;
  margin: auto;
}

.form__form-group-label {
  margin-bottom: 4px;
  display: inline-block;

  @include themify($themes) {
    color: themed("colorDarkText");
  }

  span {
    color: #dddddd;
  }
}

.form__form-group-button {
  padding: 6px;
  height: 32px;
  cursor: pointer;
  transition: all 0.3s;

  @include themify($themes) {
    background: themed("colorLightHover");
    border: 1px solid themed("colorLightHover");
  }

  svg {
    fill: $color-additional;
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }

  &.active {
    svg {
      fill: #ffffff;
    }
  }
}

.form__form-group-icon {
  padding: 6px;
  height: 32px;

  @include themify($themes) {
    background: themed("colorLightHover");
    border: 1px solid themed("colorLightHover");
  }

  svg {
    fill: $color-additional;
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }
}

.form__line{
  flex:1;
  position: relative;
  &:before{
    content: "";
    width: 2px;
    border-radius: 1px;
    height: 18px;
    background-color: #a4bacf;
    position: absolute;
    top: -9px;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  @include themify($themes) {
    background-color: themed("colorWhiteBody");
    transition: background-color 5000s ease-in-out 0s;
    color: themed("colorDarkText");
    -webkit-text-fill-color: themed("colorDarkText");
  }
}


.form-control:focus {
  background-color: initial;
  box-shadow: none !important;
}



.form-label,
.rc-time-picker-input {
  @include themify($themes) {
    color: themed("colorDarkText");
  }
}

.form-control,
.rc-time-picker {
  border: none;
  @include themify($themes) {
    color: themed("colorDarkText");
  }

  font-family: "Poppins", sans-serif;
}

.rc-time-picker-input {
  @include themify($themes) {
    background-color: themed("colorWhiteBody") !important;
  }
}

.custom-checkbox-group {
  font-size: 22px;
  width: 100%;
}
::placeholder {
  @include themify($themes) {
    color: $color-gray-account
  }
}

.MuiInputBase-root {
  & * {
    @include themify($themes) {
      color: themed("colorDarkText");
    }
  }
  & input {
    border: none;
  }
  &::before {
    @include themify($themes) {
      border-bottom: 2px solid rgba(0, 0, 0, 0.87);
    }
  }
  &::after {
    border-bottom-color: #ff781f !important;
  }
}

.rc-time-picker-panel {
  width: 190px !important;
}

.rc-time-picker-panel-narrow {
  max-width: unset !important;
}

.rc-time-picker-panel-select {
  width: 94px !important;
}

.rc-time-picker-input {
  font-size: 15px !important;
  width: 190px !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border: none !important;
}

.spacing{
  height: 25px;
}

.form-addons{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;


  & span:not(.btn-text){
    font-size: 22px;
    text-align: left;
    @include themify($themes){
      color:themed("linkColor");
    }
  }

  & a{
    @include themify($themes){
      color:themed("linkColor");
    }
    font-size: 16px;
    text-decoration: underline;
    text-underline-offset: 4px;
    font-weight: bold;
    text-align: right;
  }

  @media screen and (max-width: 680px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & a{
      margin-top: 10px;
      align-self: flex-end;
    }
    & button{
      margin-top: 20px;
    }
}
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 87.5%;
  @include themify($themes){
    color: themed("redIndicator")
  }
  text-align: left;
  position: static !important;
}

.select-placeholder{
  color: $color-gray-account !important;
  & option{
    color: black;
  }
}

.input-placeholder[value=null]{
display: none;
}

.rdp-form-control{
  background: transparent;
}

.MuiDropzoneArea-root{
  @include themify($themes){
    background-color: themed("inputBackground");
    border:none;
      box-shadow: 0 1px 10px 3px themed("inputShadow");

      & .MuiDropzoneArea-icon{
        color: themed("colorDarkText");
      }

      & .MuiChip-root{
        color: themed("colorDarkText");
        border-color: themed("colorDarkText");

        & svg{
          color: themed("colorDarkText");

        }
      }
  }
}