.notifications-dropdown {
    position: absolute;
    width: 340px;
    height: 340px;
    display: flex;
    flex-direction: column;
    top: 52px;
    right: -230px;
    z-index: 111;
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.18);

  @include themify($themes) {
    background: themed("colorTopbar");
  }

    @include themify($themes) {
        color: themed("colorDarkText");
    }
    
    .notifications-items {
      height: 350px;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      text-align: center;
      span {
        display: inline-block;
        vertical-align: middle;
        line-height: normal;
      }
    }
  
    .notifications-item {
        height: 80px;
        padding: 10px;
        cursor: pointer;
        
        &:hover {
            @include themify($themes) {
                background-color: themed("colorLightHover");
            }
        }
    }
  }

  .notifications-item {
    width: 100%;
    display: flex;
    height: 80px;

    

    img {
      width: 36px;
      height: 36px;
    }
  
    .item-details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 10px 20px;
  
      .name {
        font-size: 16px;
      }
    }
  }

  .notifications-count{
      position: absolute;
      top: -10px;
      left: 60px;
  }