.collapsed-container{
    position: fixed;
    z-index: 98;
    height: calc(100vh - 80px);
    top: 80px;
    right: 0;
    transition: transform 0.3s, width 0.3s;
    max-width: 85%;

    &.chat{
        width: 50%;
        min-width: 800px;
        &.open{
            z-index: 99;
        }
    }


    @include themify($themes){
        background-color: themed("colorBackgroundBody");
    }

    &.collapsed{
        transform: translateX(100%);
    }
    &.show{
        transform: translateX(0);
    }

    &.open{
        -webkit-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.75);
        &.video-info{
            width: 100%;
           .icon-video {
                overflow: hidden;
                position: absolute;
                top:0px;
                padding-top: 120px;
                left: calc(-15% - 40px);
                padding-left: 15%;
                padding-bottom: 100%;
           }
           
        }
    }


    & .icon-instructions{
        position: absolute;
        top:20px;
        left:-40px;
        cursor: pointer;
    }


    & .icon-chat{
        position: absolute;
        top:170px;
        left:-40px;
        cursor: pointer;
    }

    & .icon-video{
        position: absolute;
        top:120px;
        left:-40px;
        cursor: pointer;
    }

    & .icon-checklist{
        position: absolute;
        top:70px;
        left:-40px;
        cursor: pointer;
    }

    & .icon-container .shared__icon{
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        color: white;
    }

    & .pdf-container{
        overflow-y: scroll;
        height: 100%;
    }

    & .video-container{
        overflow-y: scroll;
        height: 100%;
    }

    & .chat-container{
        overflow: hidden;
        height: 100%;
    }
    
    & .iframe-tutorial{
        min-height: 350;
        height: calc(100vh - 80px);
    }
}

.big-chat-container{
    padding-top: 80px;
    height: 100vh;
    padding-left: 55px;
}

.preboarding-container{
    width: 500px;
    padding: 15px 15px 0 0;
}
 .preboarding-main-container.open  + div{
    & .collapsed-container .icon-container{
        display: none;
    }   
 }