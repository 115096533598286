.hx-plugin-button {
  margin: 0.2rem 0rem !important;

  -webkit-box-shadow: 0px 2px 19px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 19px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 19px 0px rgba(0, 0, 0, 0.274);

  height: 50px;

  background-color: #232329 !important;

  svg {
    font-size: 2.4rem;
    fill: $color-orange;
  }
}


.hx-settings-panel {
  width: 240px;
  max-width: 240px;
  overflow: hidden;
}

.hx-half-sized-drawer {
  max-width: 700px;
  margin: 0 auto;
  overflow: hidden !important;
}

.expansion-panel-dark {
  @include themify($themes) {
    color: themed("colorText");
    background-color: themed("colorBackgroundBody");
  }

  .expansion-panel-details {
    @include themify($themes) {
      color: themed("colorText");
      background-color: themed("colorBackground");
    }
  }

  .hx-editor-options-button-group {
    background-color: #d5d5d5;
  }

  .expansion-panel-details {
    flex-direction: column;
    padding: 1rem 4px;
  }

  .dark-expansion-panel-details {
    background-color: #0f0f0f2c;
  }
}

.hx-open-drawer-ctn {
  position: fixed;
  top: 0px;
  left: 5px;
  z-index: 100;

  * {
    color: white;
  }
}

.hx-open-drawer-ctn.to-right {
  // left:inherit;
  left: 10px;
  top: 1rem;
  z-index: 99999;
  button {
    background: #ff6600;
    padding: 0.5rem;
    border-radius: 50px;
    margin-right: 1rem;
  }
}

.hx-editor-placeholder {

  border-top-width: 2px;
  border-bottom-width: 2px;

  svg {
    font-size: 2rem;
  }
}

.hx-editor-pdf-view {
  * {
    .react-pdf__Page {
      display: flex;
      justify-content: center;
    & .react-pdf__Page__canvas{
      margin: 0 auto;
    }
    }
  }
}

.hx-editor-enabled_element {
  border: solid #222222ab 1px;
  border-radius: 5px;
}

.hx-editor_drag_handle {
  width: 15px;
  height: 15px;

  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  border-radius: 2px;
  position: absolute;
  left: 0px;

  color: white;
  svg {
    font-size: 1rem;
  }
}

.hx-editor-styleButton {
  border-radius: 0px !important;
  
  @include themify($themes) {
    // background-color: themed("colorButton") !important;
  }

  // color: white !important;
}

.hx-editor-activeButton {
  @include themify($themes) {
    background-color: rgba(210, 210, 210, 0.37);
    // background-color: themed("colorButtonActive") !important;
  }
}

.hx-editor-text-align-center {
  * {
    text-align: center !important;
  }
}

.hx-editor-text-align-left {
  * {
    text-align: left !important;
  }
}

.hx-editor-text-align-right {
  * {
    text-align: right !important;
  }
}

.hx-button-group-rows {
  div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.hx-editor_ctn {
  .hx-editor_frame {
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: initial !important;
    }

    .hx-editor-plugin_text {
      margin: 5px 0px;
      padding: 5px;
      @include themify($themes){
        color: themed("colorDarkText");
      }
    }

    .hx-editor-plugin_plugin {
      background: rgba(128, 128, 128, 0.014);
      border-radius: 5px;
      margin: 1rem 0;



    }

    .hx-editor-plugin_plugin:hover{
      background: rgba(128, 128, 128, 0.068);


    }

    .hx-editor-plugin_plugin.hx-active {
      -webkit-box-shadow: 0px 0px 4px 3px #b3b3b352;
      box-shadow: 0px 0px 4px 3px #b3b3b352;
    }

    #hx-editor_ROOT{
      @include themify($themes){
        background-color: themed("editorBackground");
      
      }
    }

    .MuiPaper-root{
      @include themify($themes){
       color: themed("colorDarkText");
        
    
      }

      .card-header{
        background-color: red;
      }

    }

  }

}

.hx-editor-preview {
  .hx-not-active {
    border: none !important;
  }
}

.hx-disable-events {
  pointer-events: none !important;
}

.hx-editor_checklist-light {
  .dynamic-checklist_title {
    input {
      @include themify($themes){
        color: themed("colorDarkText");
      }
      
    }
  }

  .dynamic-checklist_item{
      input{
        @include themify($themes){
          color: themed("colorDarkText");
        }
      }
  }

  fieldset{
    @include themify($themes){
      border-color: themed("colorDarkText");
    }
  }

}


.hx-editor_checklist_edit{
  padding:2rem 0rem;
}


.hx-editor_checklist_view{
  .MuiFormControlLabel-label{
    color:black;
  }
  legend{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:2rem;
    font-weight: bold;
    color: #3f51b5;
  }

  padding: 3rem 0.2rem;

  border-top: solid rgba(128, 128, 128, 0.39) 2px;
  border-bottom: solid rgba(128, 128, 128, 0.39) 2px;
}


.hx-editor-element_hover_menu{
  position: relative;
  left: -65px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 36px;
  z-index:1;
}

.hx-editor_plugin_menu{
  position: absolute;
  min-width: 400px;
  z-index: 3;
  @include themify($themes){
    background-color: themed("colorButtonHover");
    color: themed("colorDarkText")
  }

  & path{
    @include themify($themes){
      color: themed("colorDarkText")
    }
  }
  &.overflowed {
    position: absolute;
    bottom: 20%;
  }
}

.hx-editor_element_active_menu{
  position: relative;
  z-index:1;
}

.hx-editor-plugin_plugin{

  input[type=text]{
    @include themify($themes){
      background-color: themed("inputBackground") !important
    }

  }

}
.hx-editor--settings_panel{
    border:none;
    display: flex;
    align-items: center;
    .card-body{
      position: absolute;
      width:auto;
      bottom:0px;
      z-index:3;
      box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.16);
      border:solid rgba(75, 75, 75, 0.226) 1px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: auto;
     

      textarea{
        @include themify($themes){
          background-color: themed("inputBackground") !important
        }
      }

      @include themify($themes){
        background-color: themed("inputBackground") !important
      }


       *{
         @include themify($themes){
           color: themed("colorDarkText") !important
         }
       }
       & input{
        @include themify($themes){
          background-color: themed("inputBackground")
        }
       }
       .btnVideo{
        @include themify($themes){
          color: themed("colorText") !important
        }
       }
    }

  
}


.hx-editor-comment_box{
  textarea{
    @include themify($themes){
      background-color: themed("inputBackground") !important
    }
  }
}


// #region ============================= CREATED STYLE FOR ENTITY SIDEBAR ==========================

.hx-editor-entity--sidebar{
  position: absolute;
  left:-50px;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: background-color 0.5s ease;
  background-color: rgba(63, 63, 63, 0);

  &:hover{
    background-color: rgba(63, 63, 63, 0.048);
  }


}



.hx-editor-entity--component_container{
  position:relative;
}



// #endregion

.hxeditor-dialog{
  z-index: 3;
  &.theme-dark>div{
    background-color: #42424c;
    color: #d9d9d9;
  }
} 

.hxeditor-pdf_icon{
  margin-left: -9px;
}

.hxeditor-folder_icon{
  margin-left: -9px;
}

.hxeditor-force_gray{
  & path{
    @include themify($themes){
      fill: themed("colorDarkText")
    }
  }
}

.editor-checkbox:not(.Mui-disabled){
  color:$color-orange !important;
}