.react-sweet-progress-line-inner {
    max-width: 100%;
}

.datatable-cell {
    float: right;
    position: relative;
    overflow: visible;
}

.onboard-stepper-container {
    & div {
        @include themify($themes) {
            background: themed("colorWhiteBody");
        }
    }
    & span {
        @include themify($themes) {
            color: themed("colorDarkText");
        }
    }
}

.react-sweet-progress-symbol {
    @include themify($themes) {
        color: themed("colorDarkText");
    }
    font-size: 14px;
    line-height: 18px;
}

.onboard-stepper-container * {
    font-family: "Poppins", sans-serif !important;
}

.react-sweet-progress {
    & .react-sweet-progress-line {
        border-radius: 5px;

        & .react-sweet-progress-line-inner {
            height: 20px;
            border-radius: 4px;
        }
        & .react-sweet-progress-line-inner:before {
            height: 20px;
            border-radius: 5px;
        }
    }
    &.success {
        & .react-sweet-progress-line-inner {
            background-color: #ff781f !important;
        }
        & .react-sweet-progress-symbol svg path {
            fill: #ff781f !important;
        }
    }
    &.active {
        & .react-sweet-progress-line-inner {
            background-color: #605f7b !important;
        }
    }
}

.onboard-time-container {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding-bottom: 2px;
    border-radius: 5px;

    @include themify($themes) {
        background-color: themed("colorBackgroundBodyLight") !important;
    }

    & p {
        font-weight: bold;
    }
}

.onboard-handle svg,
.edit-icon {
    transition: 0.3s all ease-in-out;
    transform: scale(1);

    &:hover {
        transition: 0.3s all ease-in-out;
        transform: scale(1.2);
    }
}

.onboard-handle {
    & > .onboard-button_icon:active,
    & > .onboard-button_icon:focus {
        outline: none;
    }
    &.delete-icon {
        background-color: transparent !important;
        position: absolute;
        right: 10px;
        cursor: pointer;
    }
}

.edit-icon {
    cursor: pointer;
    margin-left: 7px;
    vertical-align: initial;
}

.onboard-button_icon {
    border-radius: 0px !important;

    svg {
        width: 1.5rem;
    }

    @include themify($themes) {
        background-color: themed("colorButton") !important;
    }

    color: white !important;
}

.onboard-button_icon.no-background {
    background: none !important;
}

.category-container {
    display: flex;
    flex-direction: column;
    @include themify($themes) {
        background-color: themed("calendarBackground");
    }
    border-radius: 5px;
    margin: 5px;

    & .category-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 10px 15px;
        cursor: pointer;
    }

    & .collapse > div,
    .collapsing > div {
        padding: 0 30px;
        padding-bottom: 20px;
    }
}

.task-row {
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    background-color: $color-lighter-gray;
    padding: 7px 12px;
}

.iframe-container {
    height: calc(100vh - 150px);
    width: 100%;
}

.progress-outer {
    height: 14px;
    vertical-align: middle;
    position: relative;
    border-radius: 10px;
    @include themify($themes) {
        background: themed("colorText");
    }

    & .progress-inner {
        background-color: #f44d00;
        border-radius: 10px;
        position: absolute;
        top: 0;
        bottom: 0;
    }
}

.pdf-modal {
    width: max-content;
    max-width: 100vw;
}

.MuiAccordion-root.Mui-expanded:last-child {
    margin-bottom: 16px !important;
}
.MuiAccordion-root.Mui-expanded:first-child {
    margin-top: 16px !important;
}

.Mui-focused {
    background-color: initial !important;
}

.no-animated-progress {
    & .react-sweet-progress-line-inner::before {
        display: none;
    }
}
.dnd-header {
    height: 44px;
    padding-right: calc(2rem + 16px);
}
