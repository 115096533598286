$themes: (
  light: (
    colorBackground: black,
    colorWhiteBody: white,
    colorBackgroundBody: #ededed,
    colorText: white,
    colorDarkText: black,
    colorTextAdditional: #999999,
    colorHover: #050405,
    colorLightHover: #e6e6e6,
    colorBorder: #333246,
    colorIcon: black,
    colorFieldsBorder: #33333a,
    colorButtonActive: #505059,
    colorButton: #2a2a31,
    colorButtonHover: #ededeb,
    colorTable: #f4f4f4,
    colorTopbar: white,
    notification: #636363,
    colorButtonOutline: black,
    colorButtonBorder: black,
    greenIndicator: #15a301,
    grayIndicator:#525252,
    redIndicator:#c30101,
    calendarBackground:#f4f4f4,
    calendarText:black,
    calendarHover:#dadada,
    dayHoverShadow:0 3px 5px 0 rgba(0, 0, 0, 0.2),
    editorBackground:#f4f4f4,
    listItem:#fbfbfb,
    formBackground: #ffffff,
    inputBackground:#ffffff,
    inputShadow:rgba(164, 186, 207, 0.4),
    linkColor:#464646,
    activeIconColor:#dadada,
    colorDraggingOver : #ff66003d
  ),
  dark: (
    colorBackground: #232329,
    colorBackgroundBody: #2a2a31,
    colorWhiteBody: lighten(#2a2a31, 5%),
    colorBackgroundBodyLight: lighten(#2a2a31, 5%),
    colorTable: #2a2a30,
    colorText: #dadada,
    colorDarkText: #d9d9d9,
    colorTextAdditional: #999999,
    colorHover: #050405,
    colorLightHover: lighten(#2a2a31, 10%),
    colorBorder: #4D4D4D,
    colorIcon: #d9d9d9,
    colorFieldsBorder: #33333a,
    colorButtonActive: #505059,
    colorButton: #2a2a31,
    colorButtonHover: lighten(#2a2a31, 10%),
    colorTopbar: lighten(#2a2a31, 5%),
    notification: #d9d9d9,
    colorButtonOutline: #f34b00,
    colorButtonBorder: #d9d9d9,
    greenIndicator: #449649,
    grayIndicator:#737373,
    redIndicator:#D03540,
    calendarBackground:#2E2E36,
    calendarText:#B3B3B3,
    calendarHover:#42424D,
    dayHoverShadow:0 3px 5px 0 rgba(255, 255, 255, 0.1),
    editorBackground:#303038,
    listItem:lighten(#2a2a31, 4%),
    formBackground: #2a2a30,
    inputBackground:lighten(#2a2a31, 2%),
    inputShadow:#404047,
    linkColor:#DBDBDB,
    activeIconColor:#43434D,
    colorDraggingOver : #ececec
  ),
);

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

$colors: (
  orange: (
    backgroundColor: #ff781f,
    borderColor: #ff6600,
    textColor: #ff6600,
  ),
  blue: (
    backgroundColor: #1a3586,
    borderColor: #004481,
    textColor: #004481,
  ),
  green: (
    backgroundColor: #9ed761,
    borderColor: #87ce32,
    textColor: #87ce32,
  ),
  red: (
    backgroundColor: #f33820,
    borderColor: #ef1828,
    textColor: #ef1828,
  ),
);

@mixin colorify($colors) {
  @each $color, $map in $colors {
    .#{""+$color} & {
      $color-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($colors, $color), "#{$key}");
        $color-map: map-merge(
          $color-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $color-map: null !global;
    }
  }
}

@function colored($key) {
  @return map-get($color-map, $key);
}

$color-accent: #fd720d;
$color-accent-hover: darken($color-accent, 10%);
$color-additional: #999999;

$color-red: #ff4861;

$color-blue: #70bbfd;
$color-blue-hover: darken($color-blue, 10%);

$color-orange: #f34b00;


$color-gray: #969b9f;
$color-light-gray: #dadada;
$color-lighter-gray: #fbfbfb;
$color-gray-account: #949597;
$color-dark-gray: #727272;


$orange-gradient: linear-gradient(0.15turn, #f33a01, #ff9201 80%);

$directions: (
  ltr: (
    direction: ltr,
    right: right,
    left: left,
    margin-right: "margin-right",
    margin-left: "margin-left",
    padding-left: "padding-left",
    padding-right: "padding-right",
    border-top-left-radius: "border-top-left-radius",
    border-bottom-left-radius: "border-bottom-left-radius",
    border-top-right-radius: "border-top-right-radius",
    border-bottom-right-radius: "border-bottom-right-radius",
    border-left: "border-left",
    border-right: "border-right",
    translate: "translate(-50%, -50%);",
    mirrorY: "scale(1, 1)",
    border-right-color: "border-right-color",
    transform-sidebar: "rotate(90deg)",
    flex-flow: row nowrap,
    row: "row",
    sidebar-close: translateX(0),
    sidebar-no-desktop: translateX(calc(0%)),
    "-": "-",
  ),
  rtl: (
    direction: rtl,
    right: left,
    left: right,
    margin-right: "margin-left",
    margin-left: "margin-right",
    padding-left: "padding-right",
    padding-right: "padding-left",
    border-top-left-radius: "border-top-right-radius",
    border-bottom-left-radius: "border-bottom-right-radius",
    border-top-right-radius: "border-top-left-radius",
    border-bottom-right-radius: "border-bottom-left-radius",
    border-left: "border-right",
    border-right: "border-left",
    translate: "translate(50%, -50%);",
    mirrorY: "scale(1, -1)",
    border-right-color: "border-left-color",
    transform-sidebar: "rotate(-90deg)",
    flex-flow: row-reverse nowrap,
    row: "row-reverse",
    sidebar-close: translateX(0),
    sidebar-no-desktop: translateX(calc(100%)),
    "-": "+",
  ),
);

@mixin directify($directions) {
  @each $direction, $map in $directions {
    .#{$direction}-support & {
      $direction-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($directions, $direction), "#{$key}");
        $direction-map: map-merge(
          $direction-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $direction-map: null !global;
    }
  }
}

@function directed($key) {
  @return map-get($direction-map, $key);
}
