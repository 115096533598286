.account {
  height: 100vh;
  width: 100%;
  min-height: 100vh;
  display: flex;
  overflow-y: auto;

  .logo {
    width: 50%;
    transform: translateY(-60%);
    height: initial;
    position: absolute;
    z-index: 1;
  }


}

.account__wrapper {
  margin: auto;
  padding: 10px;
  width: 50%;
  height: 50%;

  &.hello-screen{
    width: 65%;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width:1500px){
    &.hello-screen{
      width: 75%;
    }
  }

  @media screen and (max-width:1200px){
    width: 60%;

    &.hello-screen{
      width: 70%;
    }
  }

  @media screen and (max-width:1000px){
    width: 70%;

    &.hello-screen{
      width: 80%;
    }
    .account__content h1{
      font-size: 21px;
    }

    .account__content>span:first-of-type{
      font-size: 12px;
      letter-spacing: 1px;
    }
  }

  @media screen and (max-width:600px){
    width: 100%;
    &.hello-screen{
      width: 100%;
    }
    .account__content h1{
      font-size: 18px;
      line-height: 30px;
      text-underline-offset: 3px;
    }
    .account__content>span:first-of-type{
      font-size: 8px;
      letter-spacing: 0.5px;
    }
  }

}

.account__card {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  position: relative;

}

.account__content{
  border-radius: 15px;
  box-shadow: 0 0 20px 2px rgba(0,0,0,0.25);
  width: 100%;
  height: 100%;
  z-index: 2;

  @include themify($themes){
    background-color: themed("formBackground");
  }
  
  padding: 30px 50px;

  & h1{
    font-size: 25px;
    text-decoration: underline;
    font-weight: bold;
    text-underline-offset: 6px;
  }
}

.account__btn {
  width: 100%;
  margin-right: 0;
}


.account__head {
  margin-bottom: 30px;
}


@media screen and (max-width: 520px) {
  .account__card {
    padding: 35px 30px;
    margin: 0 auto;
  }
}


.grey-account{
  color: $color-gray-account;
}

.account-icon{
  flex:1;
  height:35px;
}

