.sidebar {
  position: fixed;
  left: 0;
  z-index: 99;
  height: 100vh;
  width: 260px;
  box-shadow: 0 1px 30px 1px rgba(0, 0, 0, 0.11);
  transition: transform 0.3s, width 0.3s;
  transform: translateX(calc(-100% - 20px));
  background: black;
  
  a:not(.sidebar__category){
    display: block;
  }

  &.sidebar--show {
    transform: translateX(0);

    .sidebar__back {
      display: block;
    }
  }

  & .scroll-content-inner{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &.sidebar--login {
    .sidebar__scroll {
      width: 100%;

      & > div {
        height: 100vh;
      }
    }

    .scroll-content {
      height: 100vh;
    }

    width: calc(0.3 * 100vw);

    .logo-container {
      margin-top: auto;
      margin-bottom: auto;
    }

    .logo {
      width: 35%;
    }

    .logotype {
      width: 60%;
    }

    .logo-greetings {
      font-size: calc(5px + 1 / 6 * 10vw);
    }

    .logo-subtitle {
      color: white;
      text-decoration: underline;
      margin-top: 1vw;
      font-size: calc(5px + 0.6 * 1vw);
      text-underline-offset: 1px;
    }

    @media screen and (max-width: 825px) {
      display: none;
    }
  }
}

.sidebar__back {
  height: 100%;
  width: 100vw;
  position: absolute;
  display: none;
  background: transparent;
  border: none;
}

.sidebar__link-active {
  .sidebar__link {
    &:before {
      opacity: 1;
    }
  }
}

.sidebar__scroll {
  width: 260px;

  .scrollbar-track {
    &.scrollbar-track-y {
      width: 5px;
    }

    &.scrollbar-track-x {
      display: none !important;
    }
  }

  .scrollbar-thumb {
    opacity: 0.7;
    width: 5px;
    @include colorify($colors) {
      background: colored("backgroundColor");
    }
  }
}

.sidebar__content {
  padding-top: 15px;
  height: 100%;
  overflow: auto;

  & > div:last-child {
    width: 4px !important;

    div {
      transition: height 0.3s;
      opacity: 0.52;
    }
  }
}

.sidebar__block {
  padding: 15px 0;

  &:last-child {
    border: none;
  }
}

.sidebar__link {
  height: 55px;
  width: 260px;
  transition: all 0.3s;
  position: relative;
  cursor: pointer;
  display: flex;
  padding: 11px 20px;
  overflow: hidden;
  background: transparent;
  border: none;
  justify-content: flex-start;
  align-items: center;

  @include themify($themes) {
    color: themed("colorText");
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 2px;
    opacity: 0;
    transition: all 0.3s;
    @include colorify($colors) {
      background: colored("backgroundColor");
    }
  }

  p {
    position: absolute;
    left: 85px;
    width: 160px;
    transition: left 0.3s;
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover {
    text-decoration: none;

    &:before {
      opacity: 1;
    }
  }
}

.sidebar--collapse .sidebar__link {
  &:not(:active) {
    &:before {
      opacity: 0;
    }
  }
}

.sidebar__link-title {
  margin: 0;
  font-size: 14px;
  line-height: normal;
  position: relative;
  display: flex;
}

.sidebar__submenu {
  transition: height 0.5s 0s, padding 0.5s 0s, opacity 0.4s 0.1s;
  padding: 15px 0;

  & .sidebar__submenu {
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 0;

    .sidebar__link {
      padding-left: 53px;

      p {
        left: 53px;
      }
    }
  }

  .sidebar__link {
    padding-left: 35px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.sidebar__category-icon {
  position: absolute;
  right: 15px;
  font-size: 13px;
  font-weight: bold;
  line-height: 14px;
  opacity: 1;
  transition: opacity 0.5s 0.2s, transform 0.3s;
  color: $color-gray;
}

.sidebar__link-badge {
  width: 26px;
  height: 14px;
  background-color: $color-red;
  font-size: 8px;
  font-weight: 400;
  padding: 2px;
  margin-left: 5px;
  line-height: 9px;
  position: relative;
  text-transform: uppercase;
  border-radius: 7px;

  span {
    position: absolute;
    left: 0;
    top: 3px;
    width: 26px;
    text-align: center;
  }
}

.sidebar__wrapper--desktop {
  display: none;
}

.sidebar__category-wrap {
  & button:focus {
    outline: none;
  }
  &.sidebar__category-wrap--open {
    .sidebar__category-icon {
      transform: rotate(90deg);
    }
  }
}

.sidebar__category-new {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  top: -3px;
  display: block;
  margin-left: 5px;
  background: $color-red;
}

@media screen and (max-width: 575px) {
  .sidebar__wrapper--desktop, .sidebar--button-back>div{
    display: none;
  }
  .sidebar.sidebar--collapse {
    transform: translateX(-260px);
  }
}

@media screen and (min-width: 576px) {
  .sidebar {
    transform: translateX(0);

    &.sidebar--no-desktop {
      transform: translateX(calc(-100% - 20px));

      &.sidebar--show {
        transform: translateX(0);
      }

      .sidebar__wrapper--mobile {
        display: block;
      }
    }

    &.sidebar--collapse {
      width: 55px;
      overflow: visible;

      .sidebar__scroll,
      .sidebar__content {
        width: 55px;
        overflow: visible !important;
        transition: width 0.3s;
      }

      .sidebar__submenu {
        padding: 0 0 15px 0;
        transition: 0s;
      }

      .mentor-container,
      .footer {
        display: none;
      }

      .footer--small {
        display: block;
      }

      .sidebar__scroll {
        & > div {
          height: calc(100vh - 70px);
        }
      }

      .sidebar__link:hover{
        &:before {
          opacity: 1;
        }
      }

      .sidebar__category-wrap {
        &:hover {
          .sidebar__category {
            width: 260px;

            @include themify($themes) {
              background: themed("colorHover");
            }

            &:before {
              opacity: 1;
            }
          }

          .sidebar__submenu-wrap {
            width: 185px;
          }
        }
      }

      .sidebar__submenu-wrap {
        position: absolute;
        left: 55px;
        width: 0;
        transition: 0.3s;
        overflow: hidden;

        .sidebar__link {
          width: 185px;
          padding-left: 15px;

          p {
            position: relative;
            left: 0;
            animation: none;
          }
        }

        .sidebar__submenu-wrap {
          position: relative;
          left: 0;

          .sidebar__link {
            padding-left: 30px;
          }
        }
      }

      .sidebar__link {
        overflow: hidden;
        width: 55px;
        background-color: transparent;
        padding: 15px 7.5px;
        p {
          position: absolute;
          left: 70px;
          width: 160px;
        }

        &:hover {
          width: 260px;

          @include themify($themes) {
            background: themed("colorHover");
          }
        }
      }

      .sidebar__category-icon {
        opacity: 0;
        transition: opacity 0s;
      }

      .scrollbar-track.scrollbar-track-y {
        margin-right: 188px;
      }
    }
  }

  .sidebar__wrapper--desktop {
    display: block;
  }

  .sidebar__wrapper--mobile {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .sidebar.sidebar--no-desktop {
    transform: translateX(0);
    display: none;
  }
}


.footer-title {
  font-size: calc(5px + 0.4 * 1vw);

  &.lighter {
    color: $color-light-gray;
  }
  &.darker {
    color: $color-gray;
  }
  &.bold {
    font-weight: bold;
  }
}

.line {
  margin: 13px 0;
  width: 90%;
  height: 1.7px;
  background-color: #f4f4f4;
}

.footer-container {
  padding: 0 45px 20px 45px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: 5rem;
}

.sidebar {
  .sidebar--small.logo-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    text-align: center;
    align-items: center;
    cursor: pointer;
    height: 80px;
    position: fixed;
    top: 0;
    z-index: 5;
    background: black;
    width: 100%;
  }

  &:not(.sidebar--collapse) {
    .sidebar--small.logo-container {
      padding: 2px 5px;

      & .logo {
        height: 75px;
        width: initial;
        padding-right: 5px;
      }

      & .logotype {
        height: 35%;
        width: initial;
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }
  &.sidebar--collapse {
    & .logo {
      height: 50px;
      width: initial;
    }

    & .logotype {
      display: none;
    }
  }
}

.footer--small {
  display: none;
  text-align: center;
  height: 15px;
  margin-bottom: 10px;
  z-index: 1;
  
  & span {
    color: $color-dark-gray;
    font-weight: bold;
  }
}

.footer {
  display: flex;
  height: 130px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 10px 15px 10px;
  margin-top: 10px;

  & * {
    color: $color-dark-gray;
  }

  & span:nth-child(1) {
    padding-top: 5px;
  }
  & span:nth-child(2) {
    font-weight: bold;
  }

  & span:nth-child(3) {
    padding-top: 20px;
    font-size: 95%;
  }
}

.sidebar--button-back {
  position: fixed;
  cursor: pointer;
  bottom: 30px;
  transition: all 0.3s;
}

.sidebar__category{
  display: flex;
}