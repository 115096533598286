.profile {

}

.profile__card {
  height: 100%;
  padding: 0;

  .nav-item {
    text-transform: uppercase;
    font-weight: 500;
  }

  .tab-content {
    padding: 0 30px 40px 30px;
  }




  &--calendar {
    .rbc-toolbar {
      -webkit-flex-wrap: nowrap!important;
      flex-wrap: nowrap!important;
    }
  }
}

.profile__information {
  padding: 30px 40px;
  display: flex;

  @include directify($directions) {
    text-align: directed('left');
  }

  @media (max-width: 1345px) and (min-width: 1200px) {
    padding: 30px 15px;
  }

  @media screen and (max-width: 360px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .profile__data {
    @media screen and (max-width: 360px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 0;
    }
  }
}

.profile__avatar {
  height: 140px;
  width: 140px;
  overflow: hidden;
  border-radius: 37%;

  img {
    height: 100%;
  }

  @media (max-width: 1345px) and (min-width: 1200px) {
    height: 130px;
    width: 130px;
  }
}

.profile__stats {
  display: flex;
  justify-content: space-around;
  @include themify($themes){
    border-top: 1px solid themed("colorBorder");

  }
}

.profile__stat {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.profile__stat-number {
  color: $color-blue;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
}

.profile__stat-title {
  margin: 0;
  color: $color-additional;
  font-size: 12px;
  line-height: 14px;
}

.profile__data {
  width: max-content;
}

.profile__name {
  font-weight: 900;
  text-transform: uppercase;
  margin: 0;
  line-height: 18px;
}

.profile__work {
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 0;
  opacity: 0.6;
  line-height: 18px;
}

.profile__contact {
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 18px;
}

.profile__btn {
  margin-top: 10px;
  margin-bottom: 0;
  padding: 8px 15px;
}
