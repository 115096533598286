.mentor-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 80px;
    padding: 50px 5px;
    border-width: 2px 0px;
    border-style: solid;
    border-color:  $color-gray;

    & span{
        color:$color-gray;
        padding: 5px 0;
    }

    & span:first-of-type{
        font-size: 110%;
        font-weight: bold;
    }

    & .icon-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        padding: 20px 30px 0 30px;
    }
}