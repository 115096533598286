.expandable-box--container{
    border-radius: 10px !important;
    @include themify($themes){
        background-color: inherit;
    }
    box-shadow: none !important;
    margin: 5px 0px;

    & .MuiIconButton-label{
        @include themify($themes){
            color: themed("colorDarkText") !important;
        }
    }
}

.expandable-box--container::before{
    display: none !important;
}

.expandable-box--item{
    @include themify($themes){
        background-color: themed("listItem");
        color:themed("colorDarkText")
    }
    margin: 5px 0px;
    border-radius: 10px;

    -webkit-box-shadow: 0px 0px 13px -12px rgba(0,0,0,0.5); 
    box-shadow: 0px 0px 13px -12px rgba(0,0,0,0.5);

    &.disabled-draggable{
        filter: brightness(0.8);
        -webkit-filter: brightness(0.8);
    }
}

.database-manager-category-list--action-header{
    height:50px;
    margin-top: 25px !important;
}

.database-manager-addButton{
    -webkit-box-shadow: 0px 10px 15px -6px rgba(0,0,0,0.45); 
    box-shadow: 0px 10px 15px -6px rgba(0,0,0,0.45);
}

.expandable-box--container_parent{
    border-radius: 10px;
    box-shadow: none;

    @include themify($themes){
        background-color: themed("colorTable");

        &.dragging-over{
            background-color: themed("colorDraggingOver");
        }
    }



    &:hover{
        -webkit-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2) !important;
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2) !important;
    }
}

.database-manager-days_list{
    margin-top: 20px;
    border-radius: 10px;
    

    & .no-hover .expandable-box--container:hover{
        background: initial!important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
}

.onbox-db-input{
    border: 0;
    outline: none;
    color: $color-orange;
    font-weight: bold;
    font-style: italic;
    border-bottom: 1px solid rgba(0,0,0,0.15);
}

.light-base{
    & .expandable-box--container{
        box-shadow: none;

        & .expandable-box--item{
            cursor: pointer;
        }
    }
}

.db_scroll{
    height: calc(100vh - 230px);
    overflow-y: auto;
    overflow-x: hidden;

    &.assistent_scroll{
        height: calc(100vh - 430px);
    }
}

.plan_scroll{
    height: calc(100vh - 290px);
    overflow-y: auto;
    overflow-x: hidden;
}


/* width */
::-webkit-scrollbar {
    width: 6px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(222, 222, 222, .75);
    border-radius: 5px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    opacity: 0.7;
    width: 5px;
    background: rgba(255, 120,31,0.8);

    border-radius: 5px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 120,31,1);
  }