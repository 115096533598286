.rdp-input-group{
    border: 1px solid #d9d9d9;    
    border-radius: 5px;
    align-items: center;

    & .text-primary{
        color: $color-orange !important
    }

    & .bg-primary{
        background: $orange-gradient !important;
        color: white;
        border-radius: 5px !important;
    }

    & .input-group-text{
        background: none;
        border: none;
        font-size: 1.5rem;
        padding: 0 .75rem;
    }
}

.select-dropdown{
    padding: 0;

    &>div:focus{
        background: transparent;
    }
}