.checkbox-btn {
  display: flex;
  cursor: pointer;

  &:hover {
    .checkbox-btn__checkbox-custom {
      border-color: $color-accent;
    }

    .checkbox-btn__label {
      color: $color-accent;
    }
  }

  &.disabled {
    pointer-events: none;
    cursor: default;

    .checkbox-btn__checkbox-custom {
      transition: 0s;

      @include themify($themes) {
        background-color: themed("colorHover");
        border-color: themed("colorFieldsBorder");
      }

      svg {
        fill: #dddddd;
      }
    }

    .checkbox-btn__label {
      color: #dddddd;
    }
  }

  &.checkbox-btn--colored {
    .checkbox-btn__checkbox-custom {
      border-color: $color-accent;
      background-color: $color-accent;

      svg {
        fill: #ffffff;
      }
    }

    &.disabled {
      opacity: 0.4;

      .checkbox-btn__checkbox-custom {
        border-color: $color-accent;
        background-color: $color-accent;
      }
    }
  }

  &.checkbox-btn--colored-click {
    .checkbox-btn__checkbox:checked + .checkbox-btn__checkbox-custom {
      border-color: $color-accent;
      background-color: $color-accent;

      svg {
        fill: #ffffff;
      }
    }

    &.disabled {
      .checkbox-btn__checkbox:checked + .checkbox-btn__checkbox-custom {
        @include themify($themes) {
          background-color: themed("colorHover");
          border-color: themed("colorFieldsBorder");
        }

        svg {
          fill: #dddddd;
        }
      }
    }
  }

  &.checkbox-btn--button {
    background: $color-accent;
    min-width: 150px;
    color: #ffffff;
    height: 24px;
    border-radius: 4px;
    transition: all 0.3s;
    padding: 0 6px;
    width: 100%;

    .checkbox-btn__label {
      color: #ffffff;
    }

    .checkbox-btn__checkbox-custom {
      display: none;
    }

    .checkbox-btn__label-svg {
      margin: auto 4px auto auto;
      height: 16px;
      line-height: 1;

      svg {
        fill: #ffffff;
        width: 14px;
        height: 14px;
      }

      .checkbox-btn__label-check {
        display: none;
      }
    }

    .checkbox-btn__checkbox:checked ~ .checkbox-btn__label-svg {
      .checkbox-btn__label-check {
        display: block;
      }

      .checkbox-btn__label-uncheck {
        display: none;
      }
    }

    .checkbox-btn__label {
      margin: auto auto auto 0;
      padding: 0;
    }

    &:hover {
      background: $color-accent-hover;

      .checkbox-btn__label {
        color: #ffffff;
      }
    }

    &.disabled {
      opacity: 0.4;

      .checkbox-btn__label {
        color: #ffffff;
      }
    }
  }
}

.checkbox-btn__checkbox {
  display: none;
}

.checkbox-btn__checkbox-custom {
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 3px;

  @include themify($themes) {
    border: 1px solid themed("colorIcon");
  }

  svg {
    transition: all 0.3s;
    opacity: 0;
    height: 16px;
    width: 16px;
    fill: $color-accent;
    margin-top: -6px;
  }
}

.checkbox-btn__label {
  line-height: 18px;
  padding-left: 28px;
  padding-top: 2px;

  @include themify($themes) {
    color: themed("colorText");
  }
}

.checkbox-btn__checkbox-custom,
.checkbox-btn__label {
  display: inline-block;
  vertical-align: middle;
  transition: all 0.3s;
}

.checkbox-btn__checkbox:checked + .checkbox-btn__checkbox-custom {
  svg {
    opacity: 1;
  }
}

.material-checkbox {
  padding-left: 30px;
  margin-bottom: 1em;

  color: rgba(0, 0, 0, 0.54);
}

.material-checkbox__image {
  box-sizing: border-box;

  width: 20px;
  height: 20px;

  margin-left: -30px;

  border: 2px solid currentColor;
  border-radius: 2px;

  transition: background-color 0.5s, border-color 0.5s;

  cursor: pointer;
}
.material-checkbox__image::before {
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 50%;
  content: "";
  position: absolute;
  display: block;
  height: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: all 0.5s;
  width: auto;
}

.material-checkbox__label {
  vertical-align: middle;
  color: rgba(0, 0, 0, 0.87);

  cursor: pointer;
}

.material-checkbox__input:focus + .material-checkbox__image::before {
  background-color: rgba(0, 0, 0, 0.12);
  left: -8px;
  top: -8px;
  right: -8px;
  bottom: -8px;
}

.material-checkbox__input:disabled + .material-checkbox__image,
.material-checkbox__input:disabled + .material-checkbox__image + .material-checkbox__label {
  cursor: default;
  color: rgba(0, 0, 0, 0.38);
}

.material-checkbox__input:checked + .material-checkbox__image {
  background-color: $color-dark-gray;
  border-color: rgba(0, 0, 0, 0);
}
.material-checkbox__input:checked + .material-checkbox__image.md-ink-ripple {
  background-color: rgba(255, 82, 82, 0.87);
  border-color: rgba(0, 0, 0, 0);
}
.material-checkbox__input:checked + .material-checkbox__image::after {
  border-color: rgba(255, 255, 255, 0.87);
  box-sizing: border-box;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 6.66667px;
  height: 13.33333px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  border-width: 2px;
  border-style: solid;
  border-radius: 0;
  border-top: 0;
  border-left: 0;
  content: "";
}

.material-checkbox__input:checked + .material-checkbox__image.md-ink-ripple::before {
  background-color: rgba(255, 82, 82, 0.26);
}

.material-checkbox__input:checked:disabled + .material-checkbox__image {
  background-color: rgba(0, 0, 0, 0.38);
}
.material-checkbox__input:checked:disabled + .material-checkbox__image::before {
  display: none;
}

.material-checkbox__input_indeterminate + .material-checkbox__image::after {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: table;
  width: 12px;
  height: 2px;
  border-width: 2px;
  border-style: solid;
  border-top: 0;
  border-left: 0;
  content: "";
}

.checkbox-container .material-checkbox__image {
  position: relative;
}

.material-checkbox.incorrect {
  & .material-checkbox__image {
    background-image: linear-gradient(0.15turn, #f33a01, #fb4925 50%);
  }
  & .material-checkbox__image::after {
    border: 0;
    width: 3px;
    background: white;
  }

  & .material-checkbox__image::before {
    content: "";
    background: white;
    box-sizing: border-box;
    transform: rotate(-45deg);
    width: 3px;
    height: 13.33333px;
    position: absolute;
    margin: auto auto;
    text-align: center;
    border-radius: 0;
  }
}
