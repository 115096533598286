.footer__login{
    background-color: #F5F5F5;
    border-top: 1px solid #d9d9d9;
    height: 60px;
    box-sizing: border-box;
    display: none;
    position: fixed;
    bottom: 0;
    z-index: 99;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & span{
        font-size: 14px;
    }
    @media screen and (max-width: 825px) {
        display: flex;
    }
}