.container {
  @media screen and (min-width: 768px) {
    width: 100%;
    max-width: 1630px;
  }
}

.container__wrap {
  overflow: hidden;
  padding-left: 60px;
  padding-top: 120px;
  min-height: 100vh;
  transition: padding-left 0.3s;
  
  @include themify($themes) {
    background: themed("colorBackgroundBody");
  }

  // @media screen and (min-width: 576px) {
  //   padding-left: 250px;
  // }
}

.layout {
  & + .container__wrap{
    padding-left: 260px;
  }
  &:not(.layout--collapse){
    .sidebar--button-back {
      left: 260px;
      display: flex;
      align-items: flex-end;
      z-index: 100;

      & .shared__icon{
        position: relative;
        left: -20px;
      }
    }


    & .sidebar__scroll {
      z-index: 3;
      top: 80px;
      & > div {
        height: calc(100vh - 80px);
      }
    }
    @media screen and (max-width: 575px) {
      .sidebar--button-back{
      width: calc(100vw - 260px);
      height: 100vh;
      }
      & + .container__wrap{
        padding-left: 0;
      }
    }
  }

  &.layout--collapse {
    & + .container__wrap,
    .without-sidebar {
      padding-left: 0;

      @media screen and (min-width: 576px) {
        padding-left: 60px;
      }
    }

    .sidebar--button-back {
      left: 35px;
      bottom: 30px;
      z-index: 100;
    }

    & .sidebar__scroll {
      z-index: 3;
      top: 50px;
      & > div {
        height: calc(100vh - 50px);
      }
    }
  }

  &.layout--login {
    &:not(.layout--without-topbar) {
      @media screen and (max-width: 825px) {
        padding-top: 125px;
      }
    }
    & + .container__wrap,
    .without-sidebar {
      padding-left: calc(0.3 * 100vw);
      padding-top: 0px;
      @media screen and (max-width: 825px) {
        padding-left: 0;
        padding-bottom: 60px;
      }
    }

    & + .topbar__login {
      display: block;
    }
  }
}
