.comment__box{

    

    position: relative;

    max-width: 400px;
    width: 400px;

    textarea{
        font-size:0.8rem;
        min-height:150px;
        resize: none;
    }

    .comment-box__action_row{
        background-color: white;
        text-align: right;
        .comment-box__send-button{
            padding:1px;
            margin-right: 7px;
            margin-bottom: 3px;

            cursor: pointer;
        }
    }

    .comment-box__edit-icon{
        position:absolute;
        font-size: 2rem;
        left:-2.5rem;
        top:-0.6rem;
    }
}


@media (max-width: 1015px) {
    .comment__box{

        margin-left:0;

        .comment-box__edit-icon{
            display: none;
        }
    }
}
