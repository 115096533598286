.fileUploadDrop{
    & > div{
        min-height: auto;
        border-width: 2px;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: space-between;
        
        & > div{
            justify-content: center;
            
        }
    }

    p{
        font-size: 1rem;
        padding: 10px;
        max-width: 300px;
    }
}

.upload-preview{
    max-width: 100px;
}